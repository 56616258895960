exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessible-content-js": () => import("./../../../src/pages/accessible-content.js" /* webpackChunkName: "component---src-pages-accessible-content-js" */),
  "component---src-pages-alt-js": () => import("./../../../src/pages/alt.js" /* webpackChunkName: "component---src-pages-alt-js" */),
  "component---src-pages-aspect-js": () => import("./../../../src/pages/aspect.js" /* webpackChunkName: "component---src-pages-aspect-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-front-end-portfolio-js": () => import("./../../../src/pages/front-end-portfolio.js" /* webpackChunkName: "component---src-pages-front-end-portfolio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jargon-js": () => import("./../../../src/pages/jargon.js" /* webpackChunkName: "component---src-pages-jargon-js" */),
  "component---src-pages-seans-legacy-js": () => import("./../../../src/pages/seans-legacy.js" /* webpackChunkName: "component---src-pages-seans-legacy-js" */),
  "component---src-pages-slvoice-js": () => import("./../../../src/pages/slvoice.js" /* webpackChunkName: "component---src-pages-slvoice-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

